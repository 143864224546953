import { FC, ReactNode } from 'react';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text,
} from '@chakra-ui/react';
import { SecondaryButton } from './SecondaryButton';
import { PrimaryButton } from './PrimaryButton';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

interface ConfirmModalProps {
  title?: string;
  subtitle?: string;
  icon?: ReactNode;
  confirmText?: string;
  declineText?: string;
  onConfirm?: () => void;
  onDecline: () => void;
  isOpen?: boolean;
  loading?: boolean;
  loadingText?: string;
  bgColor?: string;
  isConfirmDisabled?: boolean;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  children,
  title,
  subtitle,
  confirmText,
  loadingText,
  icon = <FontAwesomeIcon icon={faQuestionCircle} />,
  declineText,
  onConfirm = () => ({}),
  onDecline,
  isOpen = false,
  loading = false,
  bgColor,
  isConfirmDisabled = false,
}) => {
  const { modal } = useDefaultStyles();
  return (
    <Modal isOpen={isOpen} onClose={onDecline} isCentered>
      <ModalOverlay />
      <ModalContent mx={4} borderRadius={10}>
        <ModalHeader
          py={2}
          display="flex"
          alignItems="center"
          color={modal.header.color}
          bgColor={bgColor || modal.header.bgColor}
          borderTopLeftRadius={10}
          borderTopRightRadius={10}
        >
          <Box position="absolute" top={2} left={3}>
            {icon}
          </Box>
          <Text flex={1} textAlign="center">{title}</Text>
        </ModalHeader>
        <ModalBody bgColor={modal.body.bgColor}>
          {subtitle && (
            <Text textAlign="center">{subtitle}</Text>
          )}
          {children}
        </ModalBody>
        <ModalFooter
          bgColor={modal.footer.bgColor}
          justifyContent="center"
          borderBottomLeftRadius={10}
          borderBottomRightRadius={10}
        >
          {declineText && (
            <SecondaryButton mr={3} onClick={onDecline} variant="ghost">
              {declineText}
            </SecondaryButton>
          )}
          {confirmText && (
            <PrimaryButton
              onClick={onConfirm}
              isLoading={loading}
              loadingText={loadingText}
              disabled={isConfirmDisabled}
            >
              {confirmText}
            </PrimaryButton>
          )}

        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
