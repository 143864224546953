import { FC, useState, useCallback, useEffect } from 'react';
import { Box, Image, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { DeclinedModal } from './DeclinedModal';
import { ExamReceivedModal } from './ExamReceivedModal';
import { StudentFormModal } from './StudentFormModal';
import { ThanksModal } from './ThanksModal';

const key = 'aristo.showStudentAdmissionForm';

const StudentAdmissionButton: FC = () => {
  const [modal, setModal] = useState(0);

  const init = useCallback(async () => {
    const timesShowed = parseInt(localStorage.getItem(key) ?? '0', 10);

    if (timesShowed < 2) {
      setModal(1);

      localStorage.setItem(key, String(timesShowed + 1));
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const onDecline = useCallback(() => {
    setModal(2);
  }, []);

  const onOpen = useCallback(() => {
    setModal(1);
  }, []);

  const onClose = useCallback(() => {
    setModal(0);
  }, []);

  const onAccept = useCallback(() => {
    setModal(3);
  }, []);

  const onSuccess = useCallback(() => {
    setModal(4);
  }, []);

  const onToggle = useCallback(() => {
    if (modal) {
      onClose();
    } else {
      onOpen();
    }
  }, [modal, onClose, onOpen]);

  return (
    <>
      <Image
        src="/images/student-survey.svg"
        width="64px"
        height="64px"
        style={{
          position: 'fixed',
          right: 32,
          bottom: 100,
          zIndex: 2,
        }}
        cursor="pointer"
        onClick={onToggle}
      />
      <Box
        style={{
          position: 'fixed',
          right: 32,
          bottom: 186,
          zIndex: 2,
        }}
        display={modal !== 0 ? 'block' : 'none'}
      >
        {
          modal === 1
            && (
              <ExamReceivedModal
                onAccept={onAccept}
                onDecline={onDecline}
              />
            )
        }
        {
          modal === 2 && (
            <DeclinedModal
              onClose={onClose}
            />
          )
        }
      </Box>
      <Modal isOpen={modal === 3} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" borderRadius="lg" boxShadow="xl" width={390}>
          <StudentFormModal onClose={onClose} onSuccess={onSuccess} />
        </ModalContent>
      </Modal>
      <Modal isOpen={modal === 4} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="transparent" borderRadius="lg" boxShadow="xl" width={296}>
          <ThanksModal onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default StudentAdmissionButton;
