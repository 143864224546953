import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import React, { createContext, FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CommentDtoProps, ContentType } from '../views/_components/Forum/types/forum2.types';
import { LimitsForComments, RateHarrisonCommentResponse, useForum2API } from '../hooks/useForum2API';
import { PrivateContext } from '../Private.context';
import { Question } from '../api/agenda/questions';

type DisclosureType = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isControlled: boolean;
};

export interface Forum2Interface {
  comments: CommentDtoProps[],
  setComments: React.Dispatch<React.SetStateAction<CommentDtoProps[]>>,
  updateComments: (incomingComments: CommentDtoProps[]) => void;
  forumRef: React.RefObject<HTMLDivElement>;
  forumDisclosure: DisclosureType,
  isCurrUserSpecialist: boolean,
  setIsCurrUserSpecialist: React.Dispatch<React.SetStateAction<boolean>>,
  answerActive: string,
  setAnswerActive: React.Dispatch<React.SetStateAction<string>>,
  courseId: string,
  onCreateComment: (props: {
    body: string;
    parentId?: string;
    contentId: string;
    courseIdFromParam: string;
    level: number;
    contentType: ContentType;
  }) => Promise<CommentDtoProps>,
  onCreateHarrisonComment: (props: {
    body: string;
    parentId?: string;
    contentId: string;
    courseIdFromParam: string;
    level: number;
    contentType: ContentType;
    commentId: string;
  }) => CommentDtoProps,
  onRateAnswer: (props: {
    rating: number;
    parentId: string;
    harrisonAnswer: string;
  }) => Promise<RateHarrisonCommentResponse>,
  getLimits: (commentIds: string[]) => Promise<void>,
  limitsForComments?: LimitsForComments,
  loadQuestionData: (data: Question) => void,
  loadAnswer: (value: number) => void,
  currentQuestion?: Question,
  answer?: number,
  setCommentLimitToMaximum: (commentId: string) => void;
  updateCommentLimit: (commentId: string) => void;
  onRequestMonitor: (commentId: string) => Promise<void>;
  onHealthCheck: () => Promise<void>,
  harrisonHealth: boolean,
}

export type UpdateCommentAnswersType = {
  firstLevelCommentId: string,
  newComments: CommentDtoProps[],
}

export type HarrisonComments = {
  commentId: string,
  comment: string,
}

export const Forum2Context = createContext({} as Forum2Interface);

export const Forum2Storage: FC = ({ children }) => {
  const [comments, setComments] = useState<CommentDtoProps[]>([]);
  const [isCurrUserSpecialist, setIsCurrUserSpecialist] = useState(false);
  const [answerActive, setAnswerActive] = useState<string>('');
  const [limitsForComments, setLimitsForComments] = useState<LimitsForComments>();
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [answer, setAnswer] = useState<number>();
  const [lastFetch, setLastFetch] = useState<Date | null>(null);
  const [harrisonHealth, setHarrisonHealth] = useState(false);

  const forumRef = useRef<HTMLDivElement>(null);
  const forumDisclosure = useDisclosure();
  const { createComment, getLimitsForComments, requestMonitor,
    rateAnswer, healthCheck } = useForum2API();
  const { profile } = useContext(PrivateContext);

  const courseId = useMemo(() => {
    if (profile) {
      return profile.courses.map(c => c.course)[0];
    } return '';
  }, [profile]);

  const updateComments = useCallback((incomingComments: CommentDtoProps[]) => {
    setComments(prev => {
      const previousCommentsIds = prev.map(x => x.id);
      const nonExistentComments = incomingComments.filter(x => !previousCommentsIds.includes(x.id));
      const updatedAlreadyExistentComments = prev.map(prevComment => {
        const currComment = incomingComments.filter(x => x.id === prevComment.id)[0];
        const updatedComment = { ...prevComment, ...currComment };
        return updatedComment;
      });

      const sortedComments = [...updatedAlreadyExistentComments, ...nonExistentComments]
        .sort((a, b) => {
          return moment(a.createdAt) > moment(b.createdAt) ? -1 : 1;
        });
      return sortedComments;
    });
  }, [setComments]);

  const addCommentToLimits = useCallback((commentId: string) => {
    if (!limitsForComments) {
      return;
    }

    const copy = [...limitsForComments.comments, { commentId, currentUsageCount: 0 }];
    setLimitsForComments({
      comments: copy,
      maxUsageCount: limitsForComments.maxUsageCount,
    });
  }, [limitsForComments]);

  const onCreateComment = useCallback(async ({
    body,
    parentId,
    contentId,
    level,
    contentType,
    courseIdFromParam,
  }: {
    body: string;
    parentId?: string;
    contentId: string;
    level: number,
    contentType: ContentType,
    courseIdFromParam?: string,
  }) => {
    const res = await createComment({
      body,
      parentId,
      contentId,
      contentType,
      courseId: courseIdFromParam ?? courseId,
    });
    addCommentToLimits(res);

    const newComment: CommentDtoProps = {
      body,
      children: [],
      contentId,
      contentType,
      courseId,
      createdAt: (new Date()).toISOString(),
      id: res,
      level: level - 1,
      user: {
        email: profile?.email || '',
        name: profile?.name || '',
        id: profile?._id || '',
        externalId: profile?.uid,
      },
      parent: parentId,
      status: {
        isPublic: true,
        isFromModerator: false,
        answerStatus: 'waitingAnswer',
      },
      authorType: 'User',
    };

    return newComment;
  }, [courseId, createComment, profile?._id, profile?.email, profile?.name, profile?.uid, addCommentToLimits]);

  const setCommentLimitToMaximum = useCallback((commentId: string) => {
    if (!limitsForComments) {
      return;
    }

    const copy = [...limitsForComments.comments];

    const index = copy.findIndex(it => it.commentId === commentId);

    if (index === -1) {
      console.log('ENTROU');
      return;
    }

    copy[index] = { commentId, currentUsageCount: limitsForComments.maxUsageCount };
    console.log(copy);
    setLimitsForComments({
      comments: copy,
      maxUsageCount: limitsForComments.maxUsageCount,
    });
  }, [limitsForComments]);

  const updateCommentLimit = useCallback((commentId: string) => {
    if (!limitsForComments) {
      return;
    }

    const index = limitsForComments.comments.findIndex(it => it.commentId === commentId);

    const copy = [...limitsForComments.comments];

    if (index === -1) {
      copy.push({ commentId, currentUsageCount: 1 });
      return;
    }

    copy[index] = { commentId, currentUsageCount: copy[index].currentUsageCount + 1 };

    setLimitsForComments({
      comments: copy,
      maxUsageCount: limitsForComments.maxUsageCount ?? 3,
    });
  }, [limitsForComments]);

  const onRequestMonitor = useCallback(async (commentId: string) => {
    await requestMonitor({ commentId });
    setCommentLimitToMaximum(commentId);
  }, [requestMonitor, setCommentLimitToMaximum]);

  const onCreateHarrisonComment = useCallback(({
    body,
    parentId,
    contentId,
    level,
    contentType,
    courseIdFromParam,
    commentId,
  }: {
    body: string,
    parentId?: string,
    contentId: string,
    level: number,
    contentType: ContentType,
    courseIdFromParam?: string,
    commentId: string,
  }) => {
    setCommentLimitToMaximum(parentId ?? '');
    const newComment: CommentDtoProps = {
      body,
      children: [],
      contentId,
      contentType,
      courseId,
      createdAt: (new Date()).toISOString(),
      id: commentId,
      level: level - 1,
      parent: parentId,
      status: {
        isPublic: true,
        isFromModerator: false,
        answerStatus: 'waitingAnswer',
      },
      authorType: 'HarrisonAI',
    };

    toast.success('Obrigado pelo seu feedback! A resposta do Harrison foi publicada.');

    return newComment;
  }, [courseId, setCommentLimitToMaximum]);

  const getLimits = useCallback(async (commentIds: string[]) => {
    const res = await getLimitsForComments({ commentIds });

    setLimitsForComments(res);
  }, [getLimitsForComments]);

  const onRateAnswer = useCallback(async ({ rating, parentId, harrisonAnswer } : {
    rating: number, parentId: string, harrisonAnswer: string,
  }) => {
    const res = await rateAnswer({
      rating, parentId, harrisonAnswer,
    });
    return res;
  }, [rateAnswer]);

  const loadQuestionData = useCallback((data: Question) => {
    setCurrentQuestion(data);
  }, []);

  const loadAnswer = useCallback((value: number) => {
    setAnswer(value);
  }, []);

  const onHealthCheck = useCallback(async () => {
    if (lastFetch && new Date().getTime() - lastFetch.getTime() < 300000) {
      return;
    }

    try {
      const res = await healthCheck();
      setHarrisonHealth(res.status === 'ok');
    } catch (error) {
      setHarrisonHealth(false);
    } finally {
      setLastFetch(new Date());
    }
  }, [healthCheck, lastFetch]);

  return (
    <Forum2Context.Provider
      value={{
        isCurrUserSpecialist,
        setIsCurrUserSpecialist,
        forumDisclosure,
        comments,
        setComments,
        updateComments,
        forumRef,
        onCreateComment,
        onCreateHarrisonComment,
        answerActive,
        setAnswerActive,
        courseId,
        onRateAnswer,
        getLimits,
        limitsForComments,
        loadQuestionData,
        loadAnswer,
        currentQuestion,
        answer,
        setCommentLimitToMaximum,
        updateCommentLimit,
        onRequestMonitor,
        onHealthCheck,
        harrisonHealth,
      }}
    >
      {children}
    </Forum2Context.Provider>
  );
};
