import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  fetchAndActivate, getValue,
} from 'firebase/remote-config';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { remoteConfig } from '../../client/firebase';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';
import { PrimaryButton } from '../../lib/components/PrimaryButton';
import { PrivateContext } from '../../Private.context';
import CourseSelectionModal from './CourseSelectionModal';
import { SecondaryButton } from '../../lib/components/SecondaryButton';

interface TrialButtonInfo {
  label: string,
  url: string,
  primary?: boolean,
  type?: 'support' | 'external',
}

interface TrialEndingInfo {
  id: string,
  title: string,
  message: string[],
  buttons: TrialButtonInfo[],
}

export const TrialEndedModal: FC = () => {
  const { profile: { trialFinished, courseFinished, courses } } = useContext(PrivateContext);
  const { colors } = useDefaultStyles();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: modalIsOpen, onClose: modalOnClose, onOpen: modalOnOpen } = useDisclosure();

  const [trialEndingInfo, setTrialEndingInfo] = useState<TrialEndingInfo[]>([]);

  const shouldOpenModal = useMemo(() => {
    return (trialFinished || courseFinished) ?? false;
  }, [courseFinished, trialFinished]);

  const loadRemoteConfigData = useCallback(async () => {
    try {
      await fetchAndActivate(remoteConfig);
      const info: TrialEndingInfo[] = JSON.parse(getValue(remoteConfig, 'trialEndingInfo').asString() || '[]');
      setTrialEndingInfo(info);
    } catch (error) {
      console.error('Error fetching remote config:', error);
    }
  }, []);

  useEffect(() => {
    loadRemoteConfigData();
  }, [loadRemoteConfigData]);

  const remoteInfo = useMemo((): TrialEndingInfo | undefined => {
    // return undefined;
    return trialEndingInfo.find(e => e.id === courses[0].course);
  }, [courses, trialEndingInfo]);

  const { content, title } = useMemo(() => {
    if (remoteInfo) {
      return {
        title: remoteInfo.title,
        content: remoteInfo.message,
      };
    }
    if (courseFinished) {
      return {
        title: 'Que pena!',
        content: [
          'O seu acesso completo ao nosso curso de 2023 chegou ao fim.',
          `Para continuar aproveitando tudo o que a Aristo pode oferecer,
           clique no botão abaixo e renove a sua matrícula.`,
          'Estamos torcendo por você!',
        ],
      };
    }
    return {
      title: 'Chegou a hora!',
      content: [
        'O período de testes deste curso chegou ao fim.',
        `Para continuar aproveitando tudo o que a Aristo pode oferecer basta clicar no botão
        abaixo para efetivar a sua matrícula.`,
      ],
    };
  }, [courseFinished, remoteInfo]);

  useEffect(() => {
    modalOnOpen();
  }, [modalOnOpen, onOpen]);

  return (
    <Modal
      isOpen={shouldOpenModal && modalIsOpen}
      onClose={modalOnClose}
      isCentered
    // closeOnOverlayClick={false}
    // closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent maxW={576} p={{ base: '8px', md: '24px' }} m={2}>
        <ModalCloseButton />
        <ModalBody w="100%">
          <Flex flexFlow="column" align="center">
            <Image
              src="/images/aristo-design/p1.svg"
              width="215px"
              m={4}
            />
            <VStack textAlign="left" align="flex-start" w="100%">
              <Text fontSize="22px" fontWeight="bold">{title}</Text>
              {
                content.map(x => <Text fontSize="14px" textAlign="left" key={Math.random()}>{x}</Text>)
              }
              <Box h="4px" />
              {
                remoteInfo ? remoteInfo.buttons.map(e => {
                  if (e.primary) {
                    return (
                      <PrimaryButton
                        onClick={() => {
                          window.open(
                            e.url,
                            '_blank',
                          );
                        }}
                        fontSize="14px"
                        w="100%"
                        leftIcon={
                          e.type !== undefined
                            ? <FontAwesomeIcon icon={e.type === 'support' ? faComment : faArrowRight} />
                            : undefined
                        }
                      >
                        {e.label}
                      </PrimaryButton>
                    );
                  }
                  return (
                    <SecondaryButton
                      onClick={() => {
                        window.open(
                          e.url,
                          '_blank',
                        );
                      }}
                      fontSize="14px"
                      w="100%"
                      leftIcon={
                        e.type !== undefined
                          ? <FontAwesomeIcon icon={e.type === 'support' ? faComment : faArrowRight} />
                          : undefined
                      }
                    >
                      {e.label}
                    </SecondaryButton>
                  );
                }) : (
                  <>
                    <SecondaryButton
                      w="100%"
                      onClick={() => {
                        window.open(
                          'https://api.whatsapp.com/send/?phone=5521996998020&text&type=phone_number&app_absent=0',
                          '_blank',
                        );
                      }}
                      fontSize="14px"
                      leftIcon={<FontAwesomeIcon icon={faComment} />}
                    >
                      Fale com nossos especialistas
                    </SecondaryButton>
                    <PrimaryButton
                      w="100%"
                      onClick={() => {
                        window.open(
                          'https://aristo.com.br/',
                          '_blank',
                        );
                      }}
                      fontSize="14px"
                      leftIcon={<FontAwesomeIcon icon={faArrowRight} />}
                    >
                      Quero continuar com a Aristo
                    </PrimaryButton>
                  </>
                )
              }
              <Box h="24px" />
              {courses.length > 1 && (
                <>
                  <Button
                    w="100%"
                    variant="link"
                    onClick={onOpen}
                    color={colors.secondary.goLighter}
                    fontSize="14px"
                  >
                    Trocar de curso
                  </Button>
                  <CourseSelectionModal isOpen={isOpen} onClose={onClose} />
                </>
              )}
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
