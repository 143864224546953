import React, {
  useContext, createContext, useMemo, FC,
} from 'react';
import { CourseManyInfo, RevalidateCourse, useCoursesManyInfo } from './api/courses';
import { GlobalContext } from './Global.context';
import { PrivateContext } from './Private.context';

interface CourseContextType {
  course: CourseManyInfo[];
  revalidateCourse?: RevalidateCourse;
  loading: boolean;
}

export const CourseContext = createContext({} as CourseContextType);

export const CourseStorage: FC = ({
  children,
}) => {
  const { profile } = useContext(PrivateContext);
  const { revalidateData } = useContext(GlobalContext);

  const { data, loading } = useCoursesManyInfo(
    profile && profile.courses && profile.courses.length > 0
      ? [profile.courses[0].course]
      : [],
  );

  const course = useMemo(() => {
    if (!data || loading) {
      return [];
    }
    return [...data.coursesManyInfo];
  }, [data, loading]);

  const revalidateCourse = useMemo((): RevalidateCourse | undefined => {
    if (course.length === 0) {
      return undefined;
    }

    const { _id: id } = course[0];
    const find = revalidateData.find(e => e.id === id);

    if (!find) {
      return undefined;
    }

    if (find.type === 'upap') {
      return {
        subtitle: find.subtitle,
        badgeLabel: 'Aristo Revalida UPAP',
        color: '#980F3B',
      };
    }

    return {
      subtitle: find.subtitle,
      badgeLabel: 'Aristo Revalida',
      color: '#2E74DC',
    };
  }, [course, revalidateData]);

  return (
    <CourseContext.Provider value={{ course, loading, revalidateCourse }}>
      {children}
    </CourseContext.Provider>
  );
};
