import {
  FC, useCallback, useState,
} from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type StarsSelectProps = {
  value: number,
  onChange: (v: number) => void,
}

interface StarProps extends BoxProps {
  isActive?: boolean;
}

const Star: FC<StarProps> = ({ isActive, ...rest }) => {
  return (
    <Box
      as="button"
      color={isActive ? 'warning.500' : ''}
      px={1}
      cursor="pointer"
      {...rest}
    >
      <FontAwesomeIcon icon={isActive ? faStarSolid : faStar} />
    </Box>
  );
};

const StarsSelect: FC<StarsSelectProps> = ({
  value, onChange,
}) => {
  const [hover, setHover] = useState(0);

  const handleMouse = useCallback(num => () => {
    setHover(num);
  }, []);

  const handleClick = useCallback((val: number) => async () => {
    onChange(val);
    setHover(0);
  }, [onChange]);

  return (
    <Flex justify="center" fontSize="1.2rem" color="light.200">
      {
        Array(5).fill(0).map((_, i) => (
          <Star
            key={String(i)}
            onClick={handleClick((i + 1) * 0.2)}
            onMouseEnter={handleMouse(i + 1)}
            onMouseLeave={handleMouse(0)}
            isActive={(((value ?? 0) > (0.2 * i) && hover === 0) || hover > i)}
          />
        ))
      }
    </Flex>
  );
};

export default StarsSelect;
