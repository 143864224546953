import {
  Modal, ModalContent, ModalOverlay, Box, Flex, Text, IconButton, Textarea, HStack, Switch, Image,
} from '@chakra-ui/react';
import { faTimes, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { useOptimizerApi } from '../../../hooks/useOptimizerApi';
import { PrimaryButton } from '../../../lib/components/PrimaryButton';
import { NpsFeatures } from '../../agenda/student-admission/StudentAdmissionParams';
import StarsSelect from '../StarsSelect';

type NpsFeatureModalProps = {
  feature: NpsFeatures,
  isOpen: boolean,
  onClose: () => void,
}

export const NpsFeatureModal: FC<NpsFeatureModalProps> = ({
  feature, isOpen, onClose,
}) => {
  const [score, setScore] = useState<number>(0);
  const [feedback, setFeedback] = useState('');
  const [isUserParticipatingInTest, setIsUserParticipatingInTest] = useState(false);

  const {
    completeFeatureNpsResponse,
  } = useOptimizerApi();
  const { colors } = useDefaultStyles();

  const onScore = useCallback((v: number) => {
    setScore(v);
  }, []);

  const onCloseModal = useCallback(() => {
    setScore(0);
    setFeedback('');
    setIsUserParticipatingInTest(false);
    onClose();
  }, [onClose]);

  const submit = useCallback(async () => {
    if (!score) {
      return;
    }
    try {
      await completeFeatureNpsResponse({
        featureId: feature,
        score,
        userFeedback: feedback,
        isUserParticipatingInTest,
      });
      toast.success('Avaliação enviada');
    } catch (e) {
      toast.error('Não foi possível enviar');
    }
  }, [completeFeatureNpsResponse, feature, feedback, isUserParticipatingInTest, score]);

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} scrollBehavior="outside">
      <ModalOverlay />
      <ModalContent borderRadius="lg" width={400} p={4} position="relative">
        <Box position="absolute" right="8px" top="8px">
          <IconButton
            aria-label="close"
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={onCloseModal}
            variant="ghost"
          />
        </Box>
        <Flex flexDir="column" alignItems="center">
          <Image src="/images/guys.svg" width="341px" mb="16px" />
          <Badge />
          <Text fontWeight="bold" textAlign="center" mb="4px">Avalie o Fórum Aristo</Text>
          <Text textAlign="center" fontSize="sm" color={colors.lightGray} mb="16px" px="28px">
            Sua opinião é muito importante para nós!
            Avalie sua experiência no fórum e nos diga como podemos torná-lo ainda melhor.
          </Text>
          <StarsSelect
            value={score}
            onChange={onScore}
          />
          <Textarea
            noOfLines={5}
            value={feedback}
            h="160px"
            onChange={e => setFeedback(e.target.value)}
            placeholder="Escreva aqui sua opinião"
            resize="none"
            mt="16px"
            mb="16px"
          />
          <HStack spacing={4} mb="16px">
            <Switch
              colorScheme="blue"
              id="toggle"
              size="lg"
              isChecked={isUserParticipatingInTest}
              onChange={e => setIsUserParticipatingInTest(e.target.checked)}
            />
            <Text fontSize="sm">Gostaria de participar de testes e contribuir para a evolução da Aristo?</Text>
          </HStack>
          <PrimaryButton
            leftIcon={<FontAwesomeIcon icon={faPaperPlane} />}
            disabled={score === 0}
            borderRadius={8}
            onClick={submit}
            width="full"
          >
            Enviar feedback
          </PrimaryButton>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

const Badge: FC = () => {
  const { colors } = useDefaultStyles();

  return (
    <Box
      borderRadius={50}
      px="16px"
      py="4px"
      backgroundColor={colors.green.keep}
      color={colors.background}
      w="fit-content"
      fontSize="sm"
      mb="8px"
    >
      Feedback
    </Box>
  );
};
