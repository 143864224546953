import { Avatar, Box, Button,
  Center,
  Flex, HStack,
  Square, Stack, Text,
  Tooltip, useDisclosure, VStack,
} from '@chakra-ui/react';
import { faEllipsisV, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDefaultStyles } from '../../../../hooks/useDefaultStyles';
import { useDevice } from '../../../../hooks/useDevice';
import { PrivateContext } from '../../../../Private.context';
import { toast } from '../../../../utils/toast';
import { InlineText } from '../../InlineText.component';
import { MoreOptionsModal } from '../../MoreOptionsModal.component';
import ConfirmModal from '../../../../lib/components/ConfirmModal';
import { ZoomImg } from '../../../../lib/components/ZoomImg';
import { WholeAppContext } from '../../../agenda/WholeApp.context';
import { formatName, getUserPicture } from '../../../../utils/forum2Utils';
import { IS_DEV_ENV } from '../Forum2';
import { ForumCommentComponentType } from '../types/forum2.types';
import { Forum2Context } from '../../../../contexts/Forum2.context';
import { CommentBox } from './CommentBox';
import { HarrisonButton } from './harrison/HarrisonButton';
import { HarrisonAvatar } from './harrison/HarrisonAvatar';
import { HarrisonComment } from './harrison/HarrisonComment';

import '../scss/_all.scss';
import { HarrisonTag } from './harrison/HarrisonTag';
import { PrimaryButton } from '../../../../lib/components/PrimaryButton';
import { useForum2API } from '../../../../hooks/useForum2API';

export const Forum2Comment: FC<ForumCommentComponentType> = ({
  comment, showAnswers, setShowAnswers,
  refetchAnswers, setLoadingMoreSecondLevelComments,
  commentOfQuestion, onDelete, level, parent, onCreate,
  contentId, contentType, answersCount, courseIdFromParam,
  askHarrison, harrisonComment,
  loadingHarrison, requestMonitor, onCreateHarrisonComment, hasError, typing,
}: ForumCommentComponentType) => {
  // const {
  //   isOpen: isRatingModalOpen,
  //   onOpen: onRatingModalOpen,
  //   onClose: onRatingModalClose,
  // } = useDisclosure();
  const {
    isOpen: isMoreOptionsModalOpen,
    onOpen: onMoreOptionsModalOpen,
    onClose: onMoreOptionsModalClose,
  } = useDisclosure();
  const CONTENT_LENGTH_STEP = 400;
  const { colors } = useDefaultStyles();
  const {
    forumRef,
    forumDisclosure,
    answerActive,
    setAnswerActive,
    courseId,
    limitsForComments,
    harrisonHealth,
  } = useContext(Forum2Context);
  const { hideElementsThatTriggerModals } = useContext(WholeAppContext);

  const { requestMonitor: callMonitor } = useForum2API();

  // const handleRatingStarClick = useCallback(() => {
  //   onRatingModalOpen();
  //   setCurrCommentId(comment._id);
  // }, [comment._id, onRatingModalOpen, setCurrCommentId]);

  const device = useDevice();
  // const [isLiked, setIsLiked] = useState(false);
  // const [isRated, setIsRated] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure();
  const { forumCredentials, profile } = useContext(PrivateContext);
  const [loadingDeleteComment, setLoadingDeleteComment] = useState(false);
  const [contentVisibleLength, setContentVisibleLength] = useState(CONTENT_LENGTH_STEP);
  const [loadingChangeVisibility, setLoadingChangeVisibility] = useState(false);
  const [hasAskedMonitor, setHasAskedMonitor] = useState(false);

  const canDelete = useMemo(() => {
    return comment.user?.id === profile?._id
      || profile.roles?.includes('admin')
      || profile.roles?.includes('moderator');
  }, [comment.user?.id, profile?._id, profile.roles]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (forumRef.current
        && !forumRef.current.contains(event.target as Node)
        && !forumDisclosure.isOpen
      ) {
        setAnswerActive('');
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    const prevOnKeyDown = document.onkeydown;
    document.onkeydown = e => {
      if (e.code === 'Escape') {
        setAnswerActive('');
      }
    };
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.onkeydown = prevOnKeyDown;
    };
  }, [forumDisclosure.isOpen, forumRef, setAnswerActive]);

  // useEffect(() => {
  //   if (comment.liked && forumCredentials?.userId) {
  //     setIsLiked(comment.liked.includes(forumCredentials?.userId));
  //   }
  // }, [comment.liked, forumCredentials?.userId]);

  // useEffect(() => {
  //   if (comment.hasUserRated) {
  //     setIsRated(comment.hasUserRated);
  //   }
  // }, [comment.hasUserRated]);

  // useEffect(() => {
  //   if (comment.isPrivate) {
  //     setIsPrivate(comment.isPrivate);
  //   }
  // }, [comment.isPrivate]);

  // const likeCount = useMemo(() => {
  //   if (comment.liked && forumCredentials?.userId) {
  //     const likeInitialState = comment.liked.includes(forumCredentials?.userId);
  //     const likeCurrentState = isLiked;
  //     const delta = Number(likeCurrentState) - Number(likeInitialState);
  //     return comment.liked.length + delta;
  //   }
  //   return 0 + Number(isLiked);
  // }, [isLiked]);

  const onShowAnswersClick = useCallback(async () => {
    if (setLoadingMoreSecondLevelComments) {
      setLoadingMoreSecondLevelComments(true);
    }
    if (setShowAnswers) {
      setShowAnswers(prev => {
        if (prev === false) {
          if (refetchAnswers) {
            refetchAnswers();
          }
        }
        return !prev;
      });
    }
  }, [refetchAnswers, setLoadingMoreSecondLevelComments, setShowAnswers]);

  const onVisibilityChangeClick = useCallback(async () => {
    setLoadingChangeVisibility(true);
    try {
      // await changeCommentVisibility({
      //   commentId: comment.commentId,
      //   turnPrivate: !isPrivate,
      // });
      setIsPrivate(prev => !prev);
    } catch (e) {
      console.error(e);
      toast.error({
        title: 'Não foi possível tornar o comentário privado.',
        description: 'Cheque sua conexão e tente novamente.',
      });
    } finally {
      setLoadingChangeVisibility(false);
    }
  }, []);

  // const handleLikeComment = useCallback(async () => {
  //   if (isLiked) {
  //     try {
  //       setIsLiked(false);
  //       // await removeLikeOnComment({ commentId: comment.commentId });
  //     } catch (e) {
  //       setIsLiked(true);
  //       console.error(e);
  //       toast.error({
  //         title: 'Não foi possível descurtir o comentário.',
  //         description: 'Verifique sua conexão.',
  //       });
  //     }
  //   } else {
  //     try {
  //       setIsLiked(true);
  //       // await addLikeOnComment({
  //       //   commentId: comment.commentId,
  //       //   targetURL: redirectUrl || window.location.origin,
  //       // });
  //     } catch (e) {
  //       setIsLiked(false);
  //       console.error(e);
  //       toast.error({
  //         title: 'Não foi possível curtir o comentário.',
  //         description: 'Verifique sua conexão.',
  //       });
  //     }
  //   }
  // }, [isLiked]);

  const handleDeleteComment = useCallback(async () => {
    setLoadingDeleteComment(true);
    try {
      await onDelete();
    } catch (e) {
      toast.error({
        title: 'Não foi possível excluir o comentário.',
        description: 'Verifique sua conexão.',
      });
      console.error(e);
    } finally {
      setLoadingDeleteComment(false);
      onMoreOptionsModalClose();
    }
  }, [onDelete, onMoreOptionsModalClose]);

  const modalOptions = useMemo(() => {
    const visibilityOption = {
      label: isPrivate ? 'Tornar visível' : 'Tornar privado',
      onClick: onVisibilityChangeClick,
      color: isPrivate ? 'green' : 'orange',
    };
    const options = [
      { label: 'Excluir', onClick: onOpenDeleteModal, color: colors.red.keep },
      { label: 'Cancelar', onClick: onMoreOptionsModalClose },
    ];
    if (forumCredentials?.isSpecialist) {
      return [visibilityOption, ...options];
    }
    return options;
  }, [
    isPrivate, onVisibilityChangeClick, onOpenDeleteModal,
    colors.red.keep, onMoreOptionsModalClose, forumCredentials?.isSpecialist,
  ]);

  const isCurrCommentSelected = useMemo(() => {
    return answerActive === comment.id;
  }, [answerActive, comment.id]);

  const isCurrUserComment = useMemo(() => {
    return comment.user?.id === forumCredentials?.userId;
  }, [comment.user, forumCredentials?.userId]);

  const haveAllContentBeenShown = contentVisibleLength >= comment.body.length;

  const commentContent = useMemo(() => {
    const isBeforeNewForumImplementation = moment(comment.createdAt).isBefore(moment('20220701'));
    if (isBeforeNewForumImplementation) {
      const withoutImgLinks = comment.body.replace(/\[img.*?\[\/img\]/g, ' ');
      const withoutBBCode = withoutImgLinks.replace(/\[(.*?)\]/g, '\n');
      const contractedComment = `${withoutBBCode
        .slice(0, contentVisibleLength)}${haveAllContentBeenShown ? '' : '...'}`;
      return contractedComment;
    }
    return `${comment.body
      .slice(0, contentVisibleLength)}${haveAllContentBeenShown ? '' : '...'}`;
  }, [comment.body, comment.createdAt, contentVisibleLength, haveAllContentBeenShown]);

  const handleAnswerClick = useCallback(() => {
    setAnswerActive(comment.id);
  }, [comment.id, setAnswerActive]);

  const isFromHarrison = useMemo(() => comment.authorType === 'HarrisonAI', [comment]);

  const hasLimit = useMemo(() => {
    if (!limitsForComments) {
      return false;
    }

    const currentLimit = limitsForComments.comments.find(it => it.commentId === comment.id);

    return (currentLimit?.currentUsageCount ?? 0) < limitsForComments?.maxUsageCount;
  }, [comment?.id, limitsForComments]);

  const commentOwner = useMemo(() => {
    return comment.user?.id === profile._id;
  }, [comment.user?.id, profile._id]);

  const showHarrisonButton = useMemo(() => {
    return hasLimit && level === 1 && !harrisonComment
    && !hasError && contentType === 'Question' && comment.status.answerStatus === 'waitingAnswer'
    && !loadingHarrison && harrisonHealth && commentOwner;
  }, [level, harrisonComment, hasLimit, hasError, contentType,
    loadingHarrison, comment.status.answerStatus, harrisonHealth, commentOwner]);

  const showMentorButton = useMemo(() => {
    return ((contentType === 'Question' && level > 1) || contentType !== 'Question')
    && comment.authorType !== 'HarrisonAI' && comment.authorType !== 'Moderator'
    && comment.status.answerStatus === 'waitingAnswer' && !hasAskedMonitor && commentOwner;
  }, [comment.authorType, comment.status.answerStatus, contentType, level, hasAskedMonitor, commentOwner]);

  const handleAskMontior = useCallback(() => {
    try {
      callMonitor({ commentId: comment.id });
      setHasAskedMonitor(true);
      toast.success('Um monitor foi solicitado. O tempo de espera estimado é de até 24 horas.');
    } catch (e) {
      console.error('Error when calling a monitor!');
    }
  }, [callMonitor, comment.id]);

  if (!forumDisclosure) {
    return null;
  }

  return (
    <Flex
      overflowWrap="break-word"
      flexFlow="column"
      py={2}
      px={2}
      borderRadius={10}
      transition="all 0.4s"
    >
      <ConfirmModal
        title="Apagar este comentário?"
        icon={<FontAwesomeIcon icon="exclamation-circle" />}
        onDecline={() => {
          onCloseDeleteModal();
          onMoreOptionsModalClose();
        }}
        isOpen={isDeleteModalOpen}
        confirmText="Deletar"
        declineText="Cancelar"
        onConfirm={handleDeleteComment}
        loading={loadingDeleteComment}
        loadingText="Deletando..."
      >
        {`Deseja mesmo apagar o comentário de ${comment.user?.name}? Esta ação é irreversível!`}
      </ConfirmModal>
      {/* <RatingModal
        setIsRated={setIsRated}
        setIsLiked={setIsLiked}
        targetURL={redirectUrl || window.location.origin}
        isOpen={isRatingModalOpen}
        onClose={onRatingModalClose}
      /> */}
      <MoreOptionsModal
        options={modalOptions}
        disableAll={loadingDeleteComment || loadingChangeVisibility}
        isOpen={isMoreOptionsModalOpen}
        onClose={onMoreOptionsModalClose}
      />
      <HStack align="left" overflowWrap="break-word" w="calc(100%)">
        <VStack align="left" w="100%" color={colors.black.goInvert}>
          <Box w="100%">
            <HStack
              spacing={0}
            >
              {isFromHarrison ? (<HarrisonAvatar />) : (
                <Avatar
                  size={device === 'web' ? 'md' : 'sm'}
                  name={formatName(comment.user?.name)}
                  src={getUserPicture(comment.user?.id)}
                  marginRight={2}
                />
              )}
              <VStack alignItems="flex-start">
                <HStack>
                  <InlineText
                    fontWeight="bold"
                    color={colors.black.goInvert}
                    noOfLines={1}
                  >
                    {isFromHarrison ? 'Harrison AI' : formatName(comment.user?.name, device)}
                  </InlineText>
                  {
                    comment.status.isFromModerator && (
                      <Box
                        display="inline"
                        fontSize={device === 'mobile' ? 'xx-small' : 'xs'}
                        color={colors.secondary.keep}
                        px={2}
                        borderRadius={10}
                        border="1px solid"
                        borderColor={colors.secondary.keep}
                      >
                        Moderação
                      </Box>
                    )
                  }
                  {
                    comment.createdAt && (
                      <Text fontSize="xs" color="gray">
                        &nbsp;&bull;&nbsp;
                        {`${moment(comment.createdAt).fromNow()}`}
                      </Text>
                    )
                  }
                </HStack>
                {isFromHarrison && (
                <HarrisonTag />
                )}
                {
                  parent !== undefined && (
                    <Text
                      paddingTop={1}
                      paddingBottom={1}
                      paddingRight={2}
                      paddingLeft={2}
                      borderRadius="4px"
                      fontSize="sm"
                      backgroundColor={colors.lighter.goAlmostFullDarker}
                    >
                      {`respondendo a ${parent || 'Aristoter'}`}
                    </Text>
                  )
                }
              </VStack>
            </HStack>
          </Box>
          <HStack color="gray" align="left" fontSize="xs" w="100%" justify="left">
            {/* {
              likeCount > 0 && (
                <Text fontStyle="italic">
                  {`${likeCount} curtida${likeCount > 1 ? 's' : ''}`}
                </Text>
              )
            } */}
          </HStack>
          {
            (comment.status.isFromModerator || IS_DEV_ENV) ? (
              <ReactMarkdown
                className="inline"
                // eslint-disable-next-line no-undef
                renderers={{ image: (props): JSX.Element => <ZoomImg {...props} /> }}
              >
                {commentContent}
              </ReactMarkdown>
            ) : (
              <InlineText>
                {commentContent}
              </InlineText>
            )
          }
          {
            !haveAllContentBeenShown && (
              <InlineText
                as="button"
                fontSize="xs"
                color={colors.secondary.keep}
                onClick={() => setContentVisibleLength(prev => prev + CONTENT_LENGTH_STEP)}
              >
                (Ver mais)
              </InlineText>
            )
          }
          <VStack
            align="left"
            w="100%"
          >
            <Stack
              direction={device === 'mobile' ? 'column' : 'row-reverse'}
              color="gray"
              align="left"
              fontSize="xs"
              w="100%"
              justify="left"
            >
              {showHarrisonButton && (
              <HarrisonButton askHarrison={askHarrison} />
              )}
              {showMentorButton && (
                <PrimaryButton
                  rounded="full"
                  maxWidth="150px"
                  onClick={handleAskMontior}
                >
                  Solicitar monitor
                </PrimaryButton>
              )}
              <HStack>
                {
              (!commentOfQuestion && !isFromHarrison && level < 4 && (
                <Button
                  onClick={handleAnswerClick}
                  backgroundColor={isCurrCommentSelected ? colors.lighter.goAlmostFullDarker : colors.background}
                  style={{
                    border: `1px solid ${colors.black.goInvert}`,
                    borderRadius: 12,
                    color: isCurrCommentSelected ? colors.secondary.keep : colors.black.goInvert,
                    fontWeight: 'normal',
                  }}
                >
                  <FontAwesomeIcon icon={faCommentDots} />
                  &nbsp;&nbsp;
                  {isCurrCommentSelected ? 'respondendo' : 'responder'}
                </Button>
              )
              )
            }
                {
              answersCount > 0 && !isFromHarrison && level < 4 && (
                <Flex align="left">
                  <Button
                    onClick={onShowAnswersClick}
                    backgroundColor={isCurrCommentSelected ? colors.lighter.goAlmostFullDarker : colors.background}
                    style={{
                      border: `1px solid ${colors.black.goInvert}`,
                      borderRadius: 12,
                      color: isCurrCommentSelected ? colors.secondary.keep : colors.black.goInvert,
                      fontWeight: 'normal',
                    }}
                  >
                    {showAnswers ? 'esconder respostas' : `ver respostas (${answersCount})`}
                  </Button>
                </Flex>
              )
            }
              </HStack>
            </Stack>
          </VStack>
          <VStack
            align="left"
            pt={1}
            pl={{ base: 5, md: 10 }}
          >
            <VStack>
              {loadingHarrison && (
              <Center>
                <Text className="blink">Harrison AI está gerando sua resposta...</Text>
              </Center>
              )}
              <Box width="1px" backgroundColor="lightGray" h="100%" />
              {(harrisonComment || hasError) && (
              <HarrisonComment
                parentId={comment.id}
                askAgain={askHarrison}
                comment={harrisonComment ?? ''}
                requestMonitor={requestMonitor}
                onCreateHarrisonComment={onCreateHarrisonComment}
                loadingHarrison={loadingHarrison}
                hasError={hasError}
                hasLimit={hasLimit}
                typing={typing}
              />
              )}
            </VStack>

          </VStack>
        </VStack>
        <VStack
          align="left"
          justify="flex-start"
          overflowWrap="break-word"
          paddingTop={1}
        >
          {
            (isCurrUserComment || forumCredentials?.isSpecialist || IS_DEV_ENV)
            && canDelete
            && (!hideElementsThatTriggerModals)
            && (!commentOfQuestion)
            && (
              <Text px={2} as="button" fontSize="xs" onClick={onMoreOptionsModalOpen} color={colors.black.goInvert}>
                <FontAwesomeIcon icon={faEllipsisV} size="lg" />
              </Text>
            )
          }
        </VStack>
      </HStack>
      <Box marginTop={4}>
        {
          answerActive === comment.id && !isFromHarrison && (
            <CommentBox
              level={level}
              contentId={contentId}
              courseId={courseIdFromParam ?? courseId}
              contentType={contentType}
              loading={false}
              parentId={comment.id}
              onClick={onCreate}
            />
          )
        }
      </Box>
      <Flex align="center" justify="center" w={10}>
        {
          isPrivate ? (
            <Tooltip label="Comentário privado" hasArrow>
              <Square size={2} color={colors.alpha[400]}>
                <FontAwesomeIcon icon={faEyeSlash} />
              </Square>
            </Tooltip>
          ) : (
            <>
              {/* {
                (comment.specialist
                  && !comment.isFirstLevel
                  && !comment.hasUserRated
                  && !isRated
                  && !hideElementsThatTriggerModals)
                  ? (
                    <StarRatingButton
                      isRated={isRated}
                      onClick={handleRatingStarClick}
                    />
                  )
                  : (
                    <HeartLikeButton
                      isLiked={isLiked}
                      onClick={handleLikeComment}
                    />
                  )
              } */}
            </>
          )
        }
      </Flex>
    </Flex>
  );
};
