import {
  FC, useState, useCallback,
} from 'react';
import { Box, Text, Button, Input, VStack, Flex, Link, Center } from '@chakra-ui/react';
import { Link as RRDLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import useAccount from '../../hooks/useAccount';
import { PasswordAristo } from './_components/PasswordAristo';
import { isValidEmail } from '../../utils/isValidEmail';
import { toast } from '../../utils/toast';

const LoginForm: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailHasError, setEmailHasError] = useState(false);
  const [passwordHasError, setPasswordHasError] = useState(false);
  const history = useHistory();
  // const [message, setMessage] = useState<{ title: string, description: string }>();

  const account = useAccount();

  const queryParams = new URLSearchParams(window.location.search);
  const rev = queryParams.get('revalidate') ?? '';
  const revalidate = rev === 'upap' || rev === 'normal' ? rev : '';

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const res = await account.loginWithEmail(email, password);

      if (res === 'invalid-email') {
        setEmailHasError(true);
        toast.error({
          title: 'E-mail incorreto',
          description: 'Favor digitar seu e-mail novamente.',
        });
      }

      if (res === 'wrong-password') {
        setPasswordHasError(true);
        setPassword('');
        toast.error({
          title: 'Senha incorreta',
          description: 'Favor digitar sua senha novamente.',
        });
      }

      if (res === 'user-not-found') {
        toast.error({
          title: 'Usuário não encontrado',
          description: 'Tente novamente ou cadastre-se.',
        });
        setPassword('');
        setEmail('');
        setEmailHasError(true);
      }

      if (res === 'too-many-requests') {
        toast.error({
          title: 'Muitas tentativas',
          description: 'Tente novamente mais tarde ou altere sua senha.',
        });
        setPasswordHasError(true);
      }
      history.push('/route-manager');
    } catch (error) {
      toast.error({
        title: 'Login inválido',
        description: 'Tente novamente',
      });
    } finally {
      setLoading(false);
    }
  }, [account, email, history, password]);

  return (
    <VStack
      align="left"
      color="aristo.900"
      width="100%"
    >
      <Box mb={6}>
        <Text fontSize="24px" fontWeight="bold">
          {`Bem-vindo${revalidate === '' ? '' : ' à Aristo Revalida'}!`}
        </Text>
        <Text fontSize="16px">Faça login ou cadastre-se para acessar a plataforma</Text>
      </Box>

      <VStack align="right">
        <Input
          value={email}
          borderColor={emailHasError ? 'red.400' : 'aristo.900'}
          bgColor={emailHasError ? 'red.100' : 'secondary.50'}
          color={emailHasError ? 'red.400' : 'aristo.900'}
          onChange={e => {
            setEmail(e.target.value);
            setEmailHasError(false);
          }}
          name="email"
          type="email"
          _placeholder={{ color: emailHasError ? 'red.400' : 'light.200' }}
          placeholder={emailHasError ? 'Digite seu e-mail corretamente' : 'E-mail'}
          padding="1.4rem"
          required
          mb={2}
        />

        <PasswordAristo
          name="password"
          bgColor={passwordHasError ? 'red.100' : 'secondary.50'}
          borderColor={passwordHasError ? 'red.400' : 'aristo.900'}
          color={passwordHasError ? 'red.400' : 'aristo.900'}
          onChange={e => {
            setPasswordHasError(false);
            setPassword(e.target.value);
          }}
          setter={setPassword}
          getter={password}
          _placeholder={{ color: passwordHasError ? 'red.400' : 'light.200' }}
          placeholder={passwordHasError ? 'Digite sua senha corretamente' : 'Senha'}
        />

        <Flex justify="flex-end">
          <Link as={RRDLink} to={`/redefinir-senha${window.location.search}`}>
            <Text fontSize=".8rem" decoration="underline">Esqueceu a sua senha?</Text>
          </Link>
        </Flex>
      </VStack>

      <Box h="16px" />

      {loading ? (
        <Center>
          <FontAwesomeIcon icon={faSpinner} spin />
        </Center>
      ) : (
        <Button
          width="100%"
          height="3rem"
          bgColor="aristo.500"
          color="white"
          colorScheme="aristo"
          disabled={loading || email === '' || password === '' || !isValidEmail(email)}
          isLoading={loading}
          onClick={handleSubmit}
        >
          Entrar
        </Button>
      )}
      <Box h="16px" />
      <Flex align="center" justify="center">
        <Text fontSize="14px" color="aristo.800">
          {'Ainda não tem uma conta? '}
        </Text>
        <Text paddingLeft={1} color="aristo.500" fontSize="14px">
          <Link as={RRDLink} to={`/registrar${window.location.search}`} fontWeight="semibold">
            Cadastre-se
          </Link>
        </Text>
      </Flex>
    </VStack>
  );
};

export default LoginForm;
