import { FC, useContext, useMemo } from 'react';
import { Box, HStack, Center, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { getTheoreticalType } from '../../../hooks/getTheoreticalType';
import { PrivateContext } from '../../../Private.context';
import { TabButton } from '../../../lib/components/TabButton';
import { getDateParam } from '../../../hooks/getDateParam';
import { AgendaContext } from '../Agenda.context';
import { Divider } from '../../../lib/components/Divider';
import { CourseContext } from '../../../CourseInfoContext';
import { useDefaultStyles } from '../../../hooks/useDefaultStyles';
import { RevalidateBadge } from '../../_components/RevalidateBadge';

const SubMenu: FC = () => {
  const t = getTheoreticalType();
  const { hasAgenda: show, agenda, profile } = useContext(PrivateContext);
  const date = getDateParam();
  const history = useHistory();
  const { nExtras } = useContext(AgendaContext);
  const { colors } = useDefaultStyles();

  const { course, loading, revalidateCourse } = useContext(CourseContext);

  const userCourse = useMemo(() => {
    if (profile.courses && profile.courses.length > 0 && course && !loading) {
      return course.find(c => c._id === profile.courses[0].course);
    }
    return undefined;
  }, [course, loading, profile.courses]);

  //  TODO: remove test button

  return (
    <>
      {
        revalidateCourse !== undefined ? (
          <Box display={{ base: 'block', md: 'none' }} w="100%" mt={2}>
            <RevalidateBadge
              course={revalidateCourse}
              center
            />
          </Box>
        ) : (
          <Box display={{ base: 'block', xl: 'none' }} w="100%" mt={2}>
            <Text
              noOfLines={2}
              textAlign="center"
              fontSize={{ base: '12px', md: '16px' }}
            >
              {userCourse?.title ?? ''}
            </Text>
          </Box>
        )
      }
      {/* <Divider borderColor="light.100" /> */}
      <HStack w="100%" justify="center" py={{ base: 2, md: 4 }}>
        {
          show.theoreticalStudy && (
            <TabButton
              title="Estudo teórico"
              active={t === 'theoreticalStudy'}
              linkTo={`/agenda/estudo-teorico/${agenda?.theoreticalStudy[0].lessonId}`}
              imageSrc="/images/aristo-icons/small/theoric-study.png"
            />
          )
        }
        {
          show.theoreticalReview && (
            <TabButton
              title="Revisão teórica"
              active={t === 'theoreticalReview'}
              linkTo={`/agenda/revisao-teorica/${agenda?.theoreticalReview[0].lessonId}`}
              imageSrc="/images/aristo-icons/small/theoretical-review.png"
            />
          )
        }
        {
          show.smartReview && (
            <TabButton
              title="Revisão inteligente"
              active={t === 'smartReview'}
              linkTo="/agenda/revisao-inteligente"
              imageSrc="/images/aristo-icons/small/smart-review.png"
            />
          )
        }
        {
          show.examType === 'exams' && (
            <TabButton
              title="Prova"
              active={t === 'exams'}
              linkTo="/agenda/prova"
              imageSrc="/images/aristo-icons/small/exam.png"
            />
          )
        }
        {
          show.examType === 'mocks' && (
            <TabButton
              title="Simulado"
              active={t === 'mocks'}
              linkTo="/agenda/simulado"
              imageSrc="/images/aristo-icons/small/mock.png"
            />
          )
        }
        {
          show.examType === 'smart-exams' && (
            <TabButton
              title="Simulado inteligente"
              active={t === 'smartExams'}
              linkTo="/agenda/simulado-inteligente"
              imageSrc="/images/aristo-icons/small/smart-mock.png"
            />
          )
        }
        <Box h="48px" px={2}>
          <Divider orientation="vertical" borderColor={colors.secondary.keep} />
        </Box>

        <Box
          as="button"
          color={colors.secondary.keep}
          borderRadius="50%"
          position="relative"
          w="40px"
          h="40px"
          bgColor={colors.lighter.goALittleDarker}
          border="1px solid"
          borderColor={colors.secondary.keep}
          onClick={() => history.push({
            pathname: '/atividades-extras',
            search: `?date=${date}`,
          })}
        >
          <FontAwesomeIcon icon={faPlus} />
          {nExtras[0] !== 0 && (
            <Center
              position="absolute"
              bgColor={colors.secondary.keep}
              borderRadius="50%"
              textAlign="center"
              width="1.5rem"
              height="1.5rem"
              color={colors.lighter.keep}
              bottom={-2}
              right={-2}
            >
              {nExtras[0]}
            </Center>
          )}
        </Box>
      </HStack>
      {/* <Divider borderColor="light.100" /> */}
    </>
  );
};

export default SubMenu;
