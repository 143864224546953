import {
  FC, useState, useCallback,
} from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Text, Input, Button, HStack, VStack, useToast, Link, Box } from '@chakra-ui/react';
import useAccount from '../../hooks/useAccount';
import { isValidEmail } from '../../utils/isValidEmail';

const ForgotPasswordForm: FC = () => {
  const [emailHasError, setEmailHasError] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const setMessage = useCallback(({ title, text, status }) => (
    toast({
      position: 'top',
      title,
      description: text,
      status,
      duration: 6000,
      isClosable: true,
    })
  ), [toast]);

  const account = useAccount();

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const res = await account.sendPasswordResetEmail(email);
      if (res === 'success') {
        setMessage({
          status: 'success',
          title: 'Sucesso!',
          text: `Enviamos um código para ${email}`,
        });
      } else {
        setMessage({
          status: 'error',
          title: 'E-mail não encontrado',
          text: 'Confira se você digitou seu e-mail corretamente ou cadastre-se!',
        });
        setEmailHasError(true);
      }
    } catch (error) {
      setMessage({
        status: 'error',
        title: 'Algo deu errado...',
        text: 'Tente novamente mais tarde.',
      });
    } finally {
      setLoading(false);
    }
  }, [account, email, setMessage]);

  return (
    <VStack
      align="left"
      color="aristo.900"
    >
      <Box h="4px" />
      <Link as={RRDLink} to={`/${window.location.search}`}>
        <HStack>
          <FontAwesomeIcon icon={faChevronLeft} />
          <Text color="aristo.900">Voltar</Text>
        </HStack>
      </Link>

      <Text tag="h2" fontWeight="bold" fontSize="1.5rem">Esqueceu a senha?</Text>
      <Text>
        Entre com o seu e-mail e enviaremos um código para você redefinir sua senha
      </Text>

      <Box h="16px" />
      <Input
        value={email}
        borderColor={emailHasError ? 'red.400' : 'aristo.900'}
        bgColor={emailHasError ? 'red.100' : 'secondary.50'}
        color={emailHasError ? 'red.400' : 'aristo.900'}
        _placeholder={{ color: emailHasError ? 'red.400' : 'light.200' }}
        placeholder={emailHasError ? 'Digite seu e-mail corretamente' : 'Digite aqui o seu e-mail'}
        padding="1.5rem"
        onChange={e => {
          setEmail(e.target.value);
          setEmailHasError(false);
        }}
        name="email"
        type="email"
        required
      />
      <Box h="16px" />
      <Button
        bgColor="aristo.500"
        color="white"
        colorScheme="aristo"
        name="email"
        type="submit"
        disabled={!isValidEmail(email)}
        isLoading={loading}
        onClick={handleSubmit}
      >
        Enviar
      </Button>
    </VStack>
  );
};

export default ForgotPasswordForm;
