import { Box, Flex, Image, VStack } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '../not-found/NotFound.page';
import { RevalidateBadgeComponent } from '../_components/RevalidateBadge';
import ForgotPasswordForm from './ForgotPassword.form';
import LoginForm from './Login.form';
import RegisterForm from './Register.form';
import { EnterPageSlideShow } from './_components/EnterPageSlideShow';

const EnterPage: FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const rev = queryParams.get('revalidate') ?? '';
  const revalidate = rev === 'upap' || rev === 'normal' ? rev : '';

  const imageSrc = useMemo(() => {
    if (revalidate === 'normal') {
      return 'images/login/revalidate.png';
    }
    if (revalidate === 'upap') {
      return 'images/login/revalidate-upap.png';
    }
    return undefined;
  }, [revalidate]);

  return (
    <Flex
      backgroundColor="secondary.50"
      minHeight="100vh"
      overflow="auto"
      justifyContent="center"
      px="16px"
    >
      <Flex
        paddingTop={{ base: '32px', md: '96px', lg: '64px' }}
        flexFlow="column"
        w={{ base: 480, lg: '100vw' }}
        alignItems={{ base: 'flex-start', lg: 'center' }}
      >
        <Image
          src="images/login/logo.png"
          width={{ base: '160px', md: '208px' }}
        />
        <Box height={{ base: '64px', lg: '48px' }} />
        <Flex align="center" w="100%">
          <Box
            display={{ base: 'none', lg: 'block' }}
            flex="1"
          >
            {
              revalidate !== '' ? (
                <Flex justify="flex-end">
                  <Image
                    src={imageSrc}
                  />
                </Flex>
              ) : (
                <Flex pr="48px" justify="flex-end">
                  <EnterPageSlideShow />
                </Flex>
              )
            }
          </Box>
          <Box
            flex="1"
          >
            <VStack
              width={{ base: '100%', lg: 480 }}
              borderRadius={8}
              bgColor={{ base: '', lg: 'white' }}
              boxShadow={{ base: '', lg: '0px 0px 5px 2px rgba(0, 0, 0, .25)' }}
              padding={{ base: 0, lg: '32px' }}
              align="flex-start"
            >
              {
                revalidate !== '' && <RevalidateBadgeComponent type={revalidate} />
              }
              <Switch>
                <Route exact path="/" component={LoginForm} />
                <Route path="/registrar" component={RegisterForm} />
                <Route path="/redefinir-senha" component={ForgotPasswordForm} />
                <Route path="*" component={NotFoundPage} />
              </Switch>
              <Box h="16px" />
            </VStack>
            <Box h="64px" />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EnterPage;
