import { Flex, Box, Text } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { RevalidateCourse } from '../../api/courses';

type RevalidateBadgeProps = {
  course: RevalidateCourse,
  center?: boolean,
  subtitleColor?: string,
}

export const RevalidateBadge: FC<RevalidateBadgeProps> = ({
  course, center = false, subtitleColor,
}) => {
  return (
    <Flex flexDir="column" alignItems={center ? 'center' : 'flex-start'}>
      <Box
        mb="4px"
        background={course.color}
        borderRadius={100}
        px="8px"
        py="4px"
      >
        <Text fontWeight="bold" color="white" whiteSpace="nowrap" fontSize="14px">{course.badgeLabel}</Text>
      </Box>
      <Box
        pl="4px"
        maxW="100%"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        <Text
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          title={course.subtitle}
          fontSize="14px"
          color={subtitleColor}
        >
          {course.subtitle}
        </Text>
      </Box>
    </Flex>
  );
};

type Props = {
  type: 'upap' | 'normal';
}

export const RevalidateBadgeComponent: FC<Props> = ({
  type,
}) => {
  const revalidateBadgeData = useMemo(() => ({
    upap: {
      label: 'Revalida UPAP',
      color: '#980F3B',
    },
    normal: {
      label: 'Revalida',
      color: '#2E74DC',
    },
  }), []);

  const data = useMemo(() => {
    return revalidateBadgeData[type];
  }, [revalidateBadgeData, type]);

  return (
    <Box
      background={data.color}
      borderRadius={100}
      px="8px"
      py="4px"
    >
      <Text fontWeight="bold" color="white" whiteSpace="nowrap" fontSize="14px">{data.label}</Text>
    </Box>
  );
};
