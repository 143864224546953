import { FC, useCallback, useEffect } from 'react';
import { Center, Image } from '@chakra-ui/react';
import { signInWithCustomToken } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { auth } from '../../client/firebase';
import { getParam } from '../../hooks/useSearchParam';
import { useDefaultStyles } from '../../hooks/useDefaultStyles';

const ExternalAuthPage: FC = () => {
  const token = getParam('token');
  const history = useHistory();
  const { logo } = useDefaultStyles();

  const init = useCallback(async () => {
    if (!token.length) {
      history.push('/');
      return;
    }

    try {
      const userCredential = await signInWithCustomToken(auth, token);
      if (userCredential.user.email == null) {
        history.push('/');
      } else {
        history.push('/agenda');
      }
    } catch (error) {
      history.push('/');
    }
  }, [token, history]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <Center sx={{ height: '100vh', width: '99vw' }}>
      <Image width={200} src={logo('logo-loading')} alt="logo" />
    </Center>
  );
};

export default ExternalAuthPage;
