import { FC, useCallback, useEffect } from 'react';
import { fetchAndActivate, getBoolean } from 'firebase/remote-config';
import { usePlatformExperiment } from './api/platform-experiment';
import { auth, remoteConfig } from './client/firebase';
import { Loading } from './lib/components/Loading';

const externalRedirectUrl = process.env.REACT_APP_FLUTTER_URL;

const PlatformExperiment: FC = ({ children }) => {
  const { loading, error, data } = usePlatformExperiment();
  const { currentUser } = auth;

  const handleVerification = useCallback(async () => {
    await fetchAndActivate(remoteConfig);
    const redirectFromReactToFlutter = getBoolean(remoteConfig, 'redirectFromReactToFlutter') ?? false;
    if (currentUser && redirectFromReactToFlutter) {
      currentUser.getIdToken().then(token => {
        // eslint-disable-next-line max-len
        if (!loading && !error && data && data.userIncludedInPlatformABExperiment?.isIncludedInExperimentPlatformUserList) {
          const fullRedirectUrl = `${externalRedirectUrl}/#/external-auth?userToken=${token}`;
          window.location.href = fullRedirectUrl;
        }
      }).catch(err => {
        console.error('Error fetching user token:', err);
      });
    }
  }, [loading, error, data, currentUser]);

  useEffect(() => {
    handleVerification();
  }, [handleVerification]);

  if (loading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default PlatformExperiment;
