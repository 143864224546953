import { useCallback } from 'react';
import { api2, harrisonApi } from '../services/api';
import {
  CommentDtoProps, ContentType, ForumProps,
} from '../views/_components/Forum/types/forum2.types';

export const COMMENT_PAGE_STEP = 20;
export const VISIBLE_ANSWERS_LIMIT = 5;

export interface AskBasedOnQuestionInput {
  questionBody: string,
  questionAnswers?: string[],
  correctAnswer?: number,
  userChoice?: number,
  userQuestion?: string,
  commentId: string,
}

export type LimitsForComments = {
  comments: {
    commentId: string,
    currentUsageCount: number,
  }[],
  maxUsageCount: number,
}

export type RateHarrisonResult = 'COMMENT_CREATED' | 'MARKED_AS_BAD_COMMENT';

export type RateHarrisonCommentResponse = {
  commentId?: string,
  result: RateHarrisonResult,
}

export const useForum2API = () => {
  // const getResourceIdByInstitution = useCallback(
  //   async ({ institution }: { institution: string }) => {
  //     const resource = await api.post('/wf/forum_getResources', {
  //       institution,
  //     });

  //     return resource.data.response.foruns[0] ? resource.data.response.foruns[0].UID : '';
  //   },
  //   [],
  // );

  // const getAnswersByFirstLevelCommentId = useCallback(
  //   async ({ firstLevelCommentId }: { firstLevelCommentId: string }) => {
  //     const answers = await api.post('/wf/forum_responsesConsult', {
  //       comment: firstLevelCommentId,
  //     });
  //     return answers.data.response.comment_list;
  //   },
  //   [],
  // );

  // const rateComment = useCallback(async ({
  //   commentId, value }: {
  //     commentId: string,
  //     value: number,
  //   }) => {
  //   const res = await api.post('/wf/forum_reactionStar', {
  //     comment: commentId,
  //     value,
  //   });
  //   return res;
  // }, []);

  // const addLikeOnComment = useCallback(async ({
  //   commentId, targetURL }: {
  //     commentId: string,
  //     targetURL: string,
  //   }) => {
  //   const res = await api.post('/wf/forum_addlike', {
  //     comment: commentId,
  //     targetURL,
  //   });
  //   return res;
  // }, []);

  // const removeLikeOnComment = useCallback(async ({
  //   commentId }: {
  //     commentId: string,
  //   }) => {
  //   const res = await api.post('/wf/forum_RemoveLike', {
  //     comment: commentId,
  //   });
  //   return res.status;
  // }, []);

  // const changeCommentVisibility = useCallback(async ({
  //   commentId,
  //   turnPrivate,
  // }: {
  //   commentId: string,
  //   turnPrivate: boolean,
  // }) => {
  //   const res = await api.post('wf/forum_visibilityControl', {
  //     comment: commentId,
  //     turnPrivate,
  //   });
  //   return res.status;
  // }, []);

  const createOrGetForumByUID = useCallback(async ({
    courseId,
    contentId,
    classification,
    contentType,
    commentListPage,
    commentsPerPage,
  }: {
    courseId: string,
    contentId: string,
    classification: string[],
    contentType: ContentType,
    commentListPage?: number,
    commentsPerPage: number,
  }) => {
    const forum = await api2.post('v1/forum/get-or-create', {
      courseId,
      contentId,
      contentType,
      classification,
      commentListPage,
      commentsPerPage,
    });

    return forum.data.data as ForumProps;
  }, []);

  const getReplies = useCallback(async ({ ids }: {
    ids: string[],
  }) => {
    const res = await api2.get('v1/comment/list-by-ids', {
      params: {
        ids,
      },
    });

    return res.data.data as CommentDtoProps[];
  }, []);

  const createComment = useCallback(async ({
    body,
    parentId,
    contentId,
    contentType,
    courseId,
  }: {
    body: string,
    parentId?: string,
    contentId: string,
    courseId: string,
    contentType: ContentType,
  }) => {
    const res = await api2.post('v1/comment/creation/', {
      courseId,
      contentId,
      contentType,
      body,
      parentId,
    });

    return res.data.data as string;
  }, []);

  const deleteComment = useCallback(async ({ commentId }: {
    commentId: string
  }) => {
    await api2.delete(`v1/comment/${commentId}/delete`);
  }, []);

  const askBasedOnQuestion = useCallback(async (input: AskBasedOnQuestionInput) => {
    return api2.post('v1/harrison-ai/ask/based-on-question', input, {
      responseType: 'stream',
    });
  }, []);

  const rateAnswer = useCallback(async ({ rating, parentId, harrisonAnswer }: {
    rating: number,
    parentId: string,
    harrisonAnswer: string,
  }): Promise<RateHarrisonCommentResponse> => {
    const res = await api2.post('v1/harrison-ai/rate-answer', {
      rating,
      parentId,
      harrisonAnswer,
    });

    return res.data.data;
  }, []);

  const requestMonitor = useCallback(async ({ commentId }: {commentId: string}) => {
    await api2.post(`v1/comment/${commentId}/request-monitor`);
  }, []);

  const getLimitsForComments = useCallback(async ({
    commentIds,
  }: {commentIds: string[]}): Promise<LimitsForComments> => {
    const res = await api2.get('v1/harrison-ai/get-limits-for-usage', {
      params: {
        commentIds,
      },
    });
    return res.data.data;
  }, []);

  const healthCheck = useCallback(async () => {
    const res = await harrisonApi.get('/health');
    return res.data;
  }, []);

  return {
    createOrGetForumByUID,
    getReplies,
    createComment,
    deleteComment,
    askBasedOnQuestion,
    rateAnswer,
    requestMonitor,
    getLimitsForComments,
    healthCheck,
    // getResourceIdByInstitution,
    // deleteComment,
    // addLikeOnComment,
    // updateForumUserIdMap,
    // removeLikeOnComment,
    // getAnswersByFirstLevelCommentId,
    // rateComment,
    // changeCommentVisibility,
  };
};
